<template>
  <div name="index">
    <div class="container" style="margin: 0 10px">
      <Box title="七届市委第七轮巡察公布24个党组织常规巡察反馈情况" :titleType="false">
        <div>
          <div class="notice" style="display: block;">
            经市委批准，七届阜康市委第七轮巡察对市场监督管理局、政法委、卫健委、应急管理局、宣传部、人力资源和社会保障局、医疗保障局、商务和工业信息化局、司法局、住房和城乡建设局、统战部、发改委、网信办、昌吉州生态环境局阜康市分局、融媒体中心、人民医院、中医医院、妇幼保健院、疾病预防控制中心；滋泥泉子镇卫生院、九运街镇卫生院、水磨沟乡卫生院、三工河哈萨克族乡卫生院、上户沟哈萨克族乡卫生院共24个党组织开展了常规巡察。9月上旬，各巡察组陆续向被巡察党组织反馈了巡察情况。</p>
            <p style="text-indent: 2em;">在反馈会上，巡察组指出了存在的问题，主要是：在贯彻落实上级决策部署、财务管理、全面从严治党、党组织建设、巡察整改等方面存在的一些突出问题。</p>
			<p style="text-indent: 2em;">巡察组还收到反映一些重点人的问题线索，已按有关规定转市纪委监委处理，此次公开公布的是巡察中发现的主要问题。</p>
			<p style="text-indent: 2em;">一、向市直部门（单位）反馈的情况</p>
			<p style="text-indent: 2em;">1.向市场监督管理局反馈巡察情况</p>
			<p style="text-indent: 2em;">主要是：行政执法“三项制度”推动缓慢，行政执法不严问题突出，安全生产责任落实不到位；履行管党治党政治责任有差距，损害群众利益问题依然存在，作风不严不实，制度执行不严格，固定资产管理混乱；履行党建主体责任不到位，党内政治生活不严谨；巡察审计问题整改不彻底。</p>
			<p style="text-indent: 2em;">2.向市委政法委反馈巡察情况</p>
			<p style="text-indent: 2em;">主要是：执法监督不严格，政治机关建设有不足，平安建设责任压的不实，铸牢中华民族共同体意识有差距，网格化服务重视不够，落实意识形态和保密工作责任制有差距；全面从严管党治党不到位，损害群众利益问题仍有发生，财务制度执行不严格；党建主体责任落实有差距，党内政治生活不严肃，巡察审计反馈问题整改不彻底。</p>
			<p style="text-indent: 2em;">3.向市卫生健康委员会反馈巡察情况</p>
			<p style="text-indent: 2em;">主要是：贯彻落实上级决策部署有差距，推动卫生健康高质量发展存在短板落实意识形态工作责任制不到位；落实全面从严治党主体责任不到位，强化为民服务的宗旨意识有落差，财经纪律执行不严，固定资产管理不到位；党建主体责任落实不到位，党内政治生活质效不高，稳定人才队伍措施有待加强。</p>
			<p style="text-indent: 2em;">4.向市应急管理局反馈巡察情况</p>
			<p style="text-indent: 2em;">主要是：落实上级决策部署不到位，应急管理能力不足，依法行政有差距，重点项目建设监管有漏洞；全面从严治党主体责任落实不到位，财务管理不严谨，合同签定不规范，固定资产管理有短板，干部作风不严不实；履行党建主体责任不到位，党内政治生活不严肃。</p>
			<p style="text-indent: 2em;">5.向市委宣传部反馈巡察情况</p>
			<p style="text-indent: 2em;">主要是：统筹“文化润疆”成效不够，推进宣传工作有短板，意识形态工作责任制落实有短板，所属群团组织职能作用发挥不明显，审读工作有短板；落实政府采购制度不到位，财务管理不严格，基层减负工作落实不到位，群众意识淡漠；党内政治生活制度执行不严格，民主议事决策有差距。</p>
			<p style="text-indent: 2em;">6.向市人力资源和社会保障局反馈巡察情况</p>
			<p style="text-indent: 2em;">主要是：稳就业促就业工作有不足，执法能力和水平不足，退休审批工作有漏洞，培训资金审核把关不严，在建项目工程支付担保制度落实不到位，人事档案管理有短板；干部作风不严不实，运用监督执纪“第一种形态”不规范，财务管理不规范，政府采购程序不严谨，内控制度未落实；落实党建工作责任制有差距，党内政治生活不严肃；巡察反馈问题整改不彻底。</p>
			<p style="text-indent: 2em;">7.向市医疗保障局反馈巡察情况</p>
			<p style="text-indent: 2em;">主要是：推动深化事业单位改革试点工作有差距，履行医疗保障职能不到位，推进“放管服”改革有欠缺；落实全面从严治党主体责任有差距，医保基金监管执法能力不足，主动服务群众有差距，财务管理不规范，固定资产管理有漏洞；民主集中制执行不严格，党内政治生活不严肃。</p>
			<p style="text-indent: 2em;">8.向市商务工信局反馈巡察情况</p>
			<p style="text-indent: 2em;">主要是：落实上级决策部署有差距，铸牢中华民族共同体意识不强，意识形态工作责任制落实不到位，助力民营企业高质量发展有差距；工作作风不实，财务管理不规范，固定资产管理有漏洞，使用零余额账户公用经费列支工会福利；议事决策制度落实不到位，党内政治生活不严肃，党员教育管理不严格。</p>
			<p style="text-indent: 2em;">9.向市司法局反馈巡察情况</p>
			<p style="text-indent: 2em;">主要是：推进行政执法“三项制度”落实不到位，村（社区）法律顾问监督管理有疏漏；践行为民服务宗旨有差距，财务管理不规范，固定资产管理有短板；执行“三重一大”集体决策制度不严格，党内政治生活不严肃，干部队伍建设有不足。</p>
			<p style="text-indent: 2em;">10.向市住房和城乡建设局巡察反馈情况</p>
			<p style="text-indent: 2em;">主要是：党组政治引领意识不强，营商环境建设有差距，履行管理职责不到位，安全生产监管工作有不足，履行法定职责存在缺失；管党治党责任落实有偏差，损害群众利益现象仍有发生，为民服务意识不强，专项维修基金监管不到位；党组主体责任落实不到位，党建基础工作薄弱；巡察问题整改未完成。</p>
			<p style="text-indent: 2em;">11.向市委统战部巡察反馈情况</p>
			<p style="text-indent: 2em;">主要是：铸牢中华民族共同体意识用力不够，意识形态工作责任制落实有欠缺，指导工商联、伊协工作不够到位，保密责任制落实不到位，国有资产管理有漏洞，内部监督缺位；党建工作责任制落实不力，党建基础工作薄弱，组织生活制度落实不到位；巡察问题整改未完成和边改边犯。</p>
			<p style="text-indent: 2em;">12.向市发展和改革委员会巡察反馈情况</p>
			<p style="text-indent: 2em;">主要是：推动经济高质量发展用劲不足，推进优化营商环境用心不够，机构改革融合工作不够有效，法治政府建设有不足，统筹发展与安全意识有欠缺；全面从严治党工作落实不到位，财务管理不规范，工会经费管理有短板，党员先锋模范作用发挥不明显；党组议事决策不规范，制度建设及执行不力，人才队伍建设存在短板；巡察整改持续跟踪问效力度不足。</p>
			<p style="text-indent: 2em;">13.向市委网信办巡察反馈情况</p>
			<p style="text-indent: 2em;">主要是：推动全市信息化建设不力，网络传播引导力不强，以网络引领铸牢中华民族共同体意识工作有差距，法治建设工作有短板；全面从严治党责任制落实有差距，财经纪律执行不到位，固定资产管理不规范，工会经费管理不规范，工作作风不实；议事决策机制不健全，党建工作抓的不实不细，执行干部人事制度不严格；巡察整改持续跟踪问效力度不足。</p>
			<p style="text-indent: 2em;">14. 向昌吉州生态环境局阜康市分局分巡察反馈情况</p>
			<p style="text-indent: 2em;">主要是：落实“乌—昌—石”区域污染防治攻坚任务有差距，防范化解生态风险不力，推动经济高质量发展有欠缺；为民服务意识不强，落实全面从严治党不力，财务管理不规范，固定资产管理有漏洞；党内政治生活不严肃，执法队伍建设存在短板；巡视反馈问题整改不到位。</p>
			<p style="text-indent: 2em;">15.向市融媒体中心巡察反馈情况</p>
			<p style="text-indent: 2em;">主要是：宣传阵地管理有差距，依法推进融媒事业高质量发展有短板，安全风险防控有欠缺，广告经营业务管理混乱；内部监督机制不健全，资金监管有漏洞，执行财经纪律不严格，解决职工关心的工资社保问题行动迟缓；落实党建主体责任不到位，党建基础工作有弱项，干部人才队伍建设有短板的问题。</p>
			<p style="text-indent: 2em;">二、向医疗系统单位党组织反馈情况</p>
			<p style="text-indent: 2em;">1.向市人民医院巡察反馈情况</p>
			<p style="text-indent: 2em;">主要是：党对公立医院全面领导的作用发挥不充分，推动公立医院高质量发展不到位，抓医院精细化管理不够有力，安全生产政治责任压得不实；落实党风廉政建设责任制不深不细，践行服务人民的医疗理念不到位，内控制度执行有偏差，医德医风建设薄弱，固定资产管理不到位；党内政治生活不严肃，医疗人才建设有不足，巡察问题整改不彻底。</p>
			<p style="text-indent: 2em;">2.向市中医医院巡察反馈情况</p>
			<p style="text-indent: 2em;">主要是：推动医疗卫生事业高质量发展用力不够，“以人民为中心”的服务理念树得不牢，党组织领导核心作用未发挥；全面从严治党责任落实不到位，医护人员服务意识淡薄，执行政府采购制度不严格，财务监管有漏洞，财务支出不规范，工会经费管理不规范；重大事项议事决策不规范，对党组织规范化建设工作重视不够，“三会一课”制度执行不规范。</p>
			<p style="text-indent: 2em;">3.向市妇幼保健院反馈巡察情况</p>
			<p style="text-indent: 2em;">主要是：落实上级决策部署有差距，履行妇幼保健职能不到位，医疗服务水平不高；落实全面从严治党主体责任有差距，医用物资采购管理不严谨，财务管理不规范，固定资产账实不符，执行工会制度有偏差；党支部班子领导核心作用发挥不充分，党内政治生活不严肃，干部队伍建设不到位。</p>
			<p style="text-indent: 2em;">4.向市疾病预防控制中心反馈巡察情况</p>
			<p style="text-indent: 2em;">主要是：政治理论学习缺乏全面性，对群众健康引导不到位，药品安全管理有漏洞，传染病源头管控不到位；履行全面从严治党主体责任不到位，干部作风不严不实，财务管理不规范，医疗物资管理混乱，化解债务能力不足；党内政治生活不严肃，队伍建设有短板。</p>
			<p style="text-indent: 2em;">5.向滋泥泉子镇卫生院巡察反馈情况</p>
			<p style="text-indent: 2em;">主要是：公共卫生服务职责落实不到位，基本医疗高质量发展有弱项，防范化解安全隐患不力，法治意识淡薄；履行财务报销手续不完备，固定资产管理不到位，工会经费管理和使用不规范，公共卫生专项资金管控不力，维修改造项目未签订合同；落实党建主体责任有差距，党的组织生活制度落实不严格，民主决策机制执行不到位；巡察问题边改边犯。</p>
			<p style="text-indent: 2em;">6.向九运街镇卫生院巡察反馈情况</p>
			<p style="text-indent: 2em;">主要是：落实基层公共卫生服务职责不到位，医疗服务质量不高，党组织责任担当作用发挥有差距；全面从严治党主体责任落实不力，党员干部日常监管不力，项目监管不到位，制度执行不严格，财务管理不规范；民主集中制度落实有差距，党建基础工作不扎实。</p>
			<p style="text-indent: 2em;">7.向水磨沟乡卫生院反馈巡察情况</p>
			<p style="text-indent: 2em;">主要是：推动公共卫生高质量发展缓慢，依法履职能力不足，安全生产存在薄弱环节；以人民为中心理念树的不牢，廉政风险防控不到位，财务管理不严格；落实党建主体责任有差距，党内政治生活不严肃，退休党员管理不到位。</p>
			<p style="text-indent: 2em;">8.向三工河哈萨克族乡卫生院巡察反馈情况</p>
			<p style="text-indent: 2em;">主要是：医疗服务水平有差距，药品质量安全管理有漏洞，公共卫生服务有短板；落实党风廉政建设责任不力，主动为民服务意识不强，执行财经纪律不严格，财务管理不规范；党建工作责任制落实不到位，党内政治生活不严肃，推进党员干部队伍建设有短板。</p>
			<p style="text-indent: 2em;">9.向上户沟哈萨克族乡卫生院巡察反馈情况</p>
			<p style="text-indent: 2em;">主要是：公共卫生服务不到位，医疗医技服务能力不足，履行监督职责有短板；落实全面从严治党不够有力，作风不实，执行财经纪律不严格，财务管理不规范；支部建设有短板，党内组织生活不严肃，落实“三会一课”制度有差距。</p>
          </div>
          
          <div class="btn-box" style="margin: 16px 30px; text-align: right">
            <van-button type="danger" round icon-position="right" icon="arrow" @click="bindReport">前往举报</van-button>
          </div>
          
        </div>
      </Box>
      <!-- 底部内容 -->
      <Footer />
    </div>

    <van-dialog v-model="reportShow" title="举报方式" confirm-button-text="署名举报" cancel-button-text="匿名举报"
      show-cancel-button :close-on-click-overlay="true" @confirm="bindReportConfirm" @cancel="bindReportCancel"
    >
      <div style="line-height: 2; font-size: 14px; padding: 8px 24px 26px; text-align: left; color: #646566">
        <p>巡视对象：阜康市</p>
        <p>信访时间：2024-04-07至2024-06-07</p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Box from '../supervise/components/Box'
export default {
  name: 'PatrolNotice',
  components: { Box },
  data() {
    return {
      reportShow: false,
	  num: 0
    }
  },
  activated() {
    this.reportShow = false
  },
  mounted() {
  	// location.reload();
	this.num = this.$route.params.id;
	if (this.num == 1) {
	    location.reload();
	}
  },
  methods: {
    bindReport() {
      this.reportShow = true
      // this.$dialog.alert({
      //   title: '举报方式',
      //   message: `巡视对象：阜康市\n信访时间：2023-09-01至2023-10-26`,
      //   messageAlign: 'left',
      //   closeOnClickOverlay: true,
      //   confirmButtonText: '署名举报',
      //   showCancelButton: true,
      //   cancelButtonText: '匿名举报',
      //   confirm: () => {
      //     console.log('确认按钮')
      //   },
      //   cancel: () => {
      //     console.log('取消按钮')
      //   }
      // });
    },
    /**
     * 点击署名举报
     */
    bindReportConfirm() {
      console.log('署名举报')
      this.reportShow = false
      this.$router.push({
        name: "patrolReport",
        query: { anonymous: 0},
      });
    },
    /**
     * 点击匿名举报
     */
    bindReportCancel() {
      console.log('匿名举报')
      this.reportShow = false
      this.$router.push({
        name: "patrolReport",
        query: { anonymous: 1 },
      });
    }
  }
}
</script>

<style lang="less" scope>
  .notice {
    margin-top: 10px;
    text-indent: 2em;
    line-height: 1.8;
    color: #333;
    font-size: 15px;
  }
</style>
